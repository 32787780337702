<template>
  <v-row v-scroll="onScroll">
    <!-- header -->
    <mini-header title="Emisión por lotes" :breadcrumbs="breadcrumbs" :hiddenTab="true" :scroll="scroll">
      <template v-slot:main v-if="$helpers.hasSomePermission(['view_batch'])">
        <v-btn @click="dialogCreate=true" color="blue-500" :ripple="false"><v-icon left>mdi-plus-box</v-icon>Crear proceso</v-btn>
      </template>
      <template v-slot:custom-tab v-if="$helpers.hasSomePermission(['view_batch'])">
        <v-tabs v-model="filter" class="pt-6 pl-12 pr-0 grey-background" :height="35" style=" position: absolute; z-index: 0 !important">
          <v-tab v-for="status in stepperList" :key="status.id" :ripple="false">
            <v-icon left small>mdi-{{status.icon}}</v-icon>{{status.title}}
            <template v-if="status.title === 'Todas'"></template>
          </v-tab>
        </v-tabs>
        <div class="pt-1" style="position: absolute">
          <v-tooltip color="white-0" left max-width="185px" transition="scale-transition" open-delay="1000">
            <template v-slot:activator="{ on }">
              <span class="grey-700--text body-1" v-on="on">
                <v-btn class="mr-2" width="35" outlined @click="mode = !mode" :disabled="!documentsList?.length">
                <v-icon size="19">mdi-{{ !mode ? 'view-grid-outline' : 'table'}}</v-icon>
              </v-btn>
              </span>
            </template>
            <span class="d-block px-3 py-2">{{ mode ? 'Ver modo tabla' : 'Ver modo grilla' }}</span>
          </v-tooltip>
          <v-btn @click="createExport" outlined :ripple="false"><v-icon left>mdi-download</v-icon>Descargar plantilla</v-btn>
        </div>
      </template>

    </mini-header>
    <!-- end header -->
    <v-col class="pa-0 mt-25">
      <v-col class="px-0" v-if="$store.state.batch.retrieveLoader">
        <skeleton-list />
      </v-col>
      <template v-else>
        <PermissionDenied v-if="!$helpers.hasSomePermission(['view_batch'])" />
        <template v-else>
          <v-col class="px-0 pb-16 mb-5" v-if="documentsList?.length">
            <v-row v-if="mode">
              <v-col v-for="d in documentsList" :key="d.title" cols="4" :md="$vuetify.breakpoint.width < 1200 ? 3 : 2">
                <v-hover v-slot="{ hover }">
                  <v-card class="pa-5 rounded-md" :elevation="hover ? 5 : 0" flat @click="goTo(d.id)">
                    <span class="d-block body-1 font-weight-bold grey-700--text">
                      {{d.count}} documentos
                    </span>
                    <span class="d-block body-2 grey-300--text d-inline-block text-truncate" style="max-width: 99%;">
                      <v-tooltip color="white-0" top max-width="350px" transition="scale-transition" open-delay="1000">
                        <template v-slot:activator="{ on }">
                          <span class="grey-700--text body-1" v-on="on">
                            {{d.name}}
                          </span>
                        </template>
                        <span class="d-block px-3 py-2">{{d.name}}</span>
                      </v-tooltip>
                    </span>
                      <v-chip class="mt-8" :color="d.status.search(/ing/) !== -1  ? 'yellow-100' : 'blue-100'" small label>
                        {{stepperList.find((s) => s.status.includes(d.status)).title}}
                        <v-icon right>mdi-{{stepperList.find((s) => s.status.includes(d.status)).icon}}</v-icon>
                      </v-chip>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
            <v-data-table
                v-else
                :class="[{'fixed-table' : $store.state.base.isExpandNavigationDrawer}]"
                :headers="headers"
                :items="documentsList"
                :items-per-page="pagination"
                hide-default-header
                disable-sort
                mobile-breakpoint
                hide-default-footer
                @click:row="(documentsList) => {goTo(documentsList.id)}"
                style="cursor: pointer;"
                item-key="id"
                fixed-header
                :height="heightTable"
              >
                <!-- header -->
                <template v-slot:header="{ props: { headers } }">
                  <VTableHeaders :headers="headers" @sort="setSort" />
                  <v-divider style="position: absolute;  margin-top: -6px; margin-left: -5px ;z-index: 2; min-width: 100%" />
                </template>
                <!-- end header -->
                <template v-slot:[`item.count`]="{item}">
                  <span class="body-1">{{item.count | number}}</span>
                </template>
                <template v-slot:[`item.name`]="{item}">
                  <span class="body-1 d-inline-block text-truncate" style="max-width: 99%;">{{item.name}}</span>
                </template>
                <template v-slot:[`item.user`]="{item}">
                  <span class="body-1">{{item.user}}</span>
                </template>
                <template v-slot:[`item.status`]="{item}">
                  <v-chip :color="item.status.search(/ing/) !== -1  ? 'yellow-100' : 'blue-100'" small label>
                    {{stepperList.find((s) => s.status.includes(item.status)).title}}
                    <v-icon right>mdi-{{stepperList.find((s) => s.status.includes(item.status)).icon}}</v-icon>
                  </v-chip>
                </template>
                <template v-slot:[`item.created`]="{item}">
                  <span>{{item.created | dateTimeSecond}}</span>
                </template>
                <template v-slot:[`item.updated`]="{item}">
                  <span>{{item.updated | dateTimeSecond}}</span>
                </template>
                <template v-slot:[`item.total`]="{item}">
                  <div class="text-right">
                    <span class="body-1">{{item.total | currency(item.document_type)}}</span>
                    <span class="grey-300--text caption ml-1">{{item.document_type | firstDocumentsTypeCurrency}}</span>
                  </div>
                </template>
                <template v-slot:[`item.actions`]="{item}">
                  <v-menu close-on-click close-on-content-click offset-y left :nudge-bottom="10" :nudge-left="10" transition="slide-y-transition">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="mr-n4" v-bind="attrs" v-on="on" text x-small><v-icon color="grey-300--text" size="24">mdi-dots-vertical</v-icon></v-btn>
                    </template>
                    <v-list class="pa-0">
                      <v-list-item class="px-3" :href="item.source" download :ripple="false">
                        <v-list-item-title class="body-2">Fuente de datos</v-list-item-title>
                      </v-list-item>
                      <v-list-item class="px-3" v-if="item.status !== 'pending'" @click="createExportErrors(item.id, item.name)" :ripple="false">
                        <v-list-item-title class="body-2">Excel de errores</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </v-data-table>
            <v-col class="pa-0" v-if="showPagination || showAll">
              <VMainPagination :count="count" />
            </v-col>
          </v-col>
          <v-empty-state v-else type="documents" id="documentos" :isFree="true" customClass="mt-n8" title="No hay proceso de emisión por lote" />
        </template>
      </template>
    </v-col>
    <!-- dialog create -->
    <v-dialog v-model="dialogCreate" :width="widthModalCreateErrors" persistent no-click-animation :scrollable="true">
      <v-card >
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 grey-500--text font-weight-semibold">{{ !errors?.length ? 'Crear proceso de emisión por lotes'
            : 'Error al cargar el archivo' }}</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogCreate=false, errors=[], clearInput()" icon small retain-focus-on-click><v-icon color="grey-300--text" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="pl-5 py-4" style="max-height: 600px !important;">
          <v-row class="pt-1" align="center">
            <v-col cols="3" v-if="errors?.length <= 6">
              <img :src="require(`@/assets/backgrounds/modal-document-${errors?.length ? 'error' : 'create'}-fl.svg`)" :height="128" />
            </v-col>
            <v-col :class="`pr-2 ${errors?.length <= 6 ? 'pl-6' : 'px-0'}`">
              <v-list class="v-list-form-main pa-0 transparent" v-if="!errors?.length">
                <v-list-item class="py-0">
                  <v-list-item-title>Fuente de datos</v-list-item-title>
                  <v-list-item-subtitle class="ml-n12">
                    <v-file-input v-model="file" :error="errorFile" ref="file" @change="setUploadFile()" type="file" accept=".xlsx" prepend="mdi-paper-clip" hide-details background-color="transparent" placeholder="No se eligió ningún archivo" solo flat chips dense />
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item class="py-2">
                  <v-list-item-title>Formato</v-list-item-title>
                  <v-list-item-subtitle class="ml-n12">
                    <v-select v-model="$v.newBatch.import_format.$model" :error="$v.newBatch.import_format.$error" :items="importFormats" readonly disabled item-text="importFormats" item-value="importFormats" hide-details single-line dense height="30" outlined />
                  </v-list-item-subtitle>
                </v-list-item>
              </v-list>
              <v-col class="pa-0" v-else>
                <span class="d-block red--500 subtitle-1 font-weight-bold px-4"><v-icon class="mr-4" color="red--500">mdi-alert</v-icon>Error en los siguientes campos</span>
                <v-simple-table class="table-detail mt-2" dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left pb-2" style="width: 60px">#Doc.</th>
                        <th class="text-left pb-2">Columna</th>
                        <th class="text-left pb-2" style="width: 230px">Descripción</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="error in errors" :key="error.number">
                        <td :class="[`text-right body-2`, {'d-flex align-start justify-end pt-2' : currentChildrenCol(error?.number)?.length}]">{{error?.number}}</td>
                        <td class="body-2 py-2">
                          <span class="d-block" v-if="!error?.hasChildren">{{error?.col}}</span>
                          <span class="d-block">{{currentChildrenCol(error?.number)}}</span>
                        </td>
                        <td class="body-2">
                          <span class="d-block" v-if="!error?.hasChildren">{{error?.description}}</span>
                          <span class="d-block" v-if="currentChildrenDescription(error?.number)?.length">{{currentChildrenDescription(error?.number)}}</span>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <v-col class="text-right pb-0">
                  <v-btn :href="errorSource" download text color="blue-500" :ripple="false"><v-icon left>mdi-download</v-icon>Descargar archivo de errores</v-btn>
                </v-col>
              </v-col>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-4 px-5">
          <v-spacer />
          <v-btn v-if="!errors?.length" @click="dialogCreate=false, clearInput()" outlined>Cancelar</v-btn>
          <v-btn v-if="errors?.length" @click="errors=[], clearInput()" outlined>Volver a subir</v-btn>
          <v-btn @click="!errors?.length ? createDocumentsBulk() : handleDialog()" :loading="!errors?.length ? $store.state.batch.createLoader : false" :disabled="(!!errorFile || !((file || {}).name || '').length)" color="blue-500">Continuar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog create -->
  </v-row>
</template>
<script>
  import { saveAs } from 'file-saver'
  import { mapState } from 'vuex'
  import { required } from 'vuelidate/lib/validators'
  import MiniHeader from '@/components/commons/MiniHeader'
  import VQueryBuilder from '@/components/VQueryBuilder/VQueryBuilder'
  import VEmptyState from '@/components/commons/VEmptyState'
  import ListViewMixin from '@/mixins/ListViewMixin'
  import VMainPagination from '@/components/commons/VMainPagination'
  import SkeletonList from '@/components/commons/skeletonLoader/SkeletonList'
  import PermissionDenied from '@/modules/http/views/PermissionDenied'
  import VTableHeaders from '@/components/VTable/VTableHeaders'

  export default {
    components: {
      VQueryBuilder,
      VEmptyState,
      SkeletonList,
      VMainPagination,
      PermissionDenied,
      MiniHeader,
      VTableHeaders
    },
    mixins: [
      ListViewMixin
    ],
  data: () => ({
      mode: false,
      errorSource: null,
      isArrayOfObjects: [],
      errorFile: false,
      scroll: 0,
      errors: [],
      file: null,
      newBatch: {
        file: null,
        import_format: 'xlsx'
      },
      importFormats: ['csv', 'xlsx', 'json'],
      filter: 0,
      dialogCreate: false,
      stepperList: [
        {
          id: 0,
          status: [],
          title: 'Todas',
          icon: 'ballot'
          // width: 'auto'
        },
        {
          id: 1,
          status: ['pending'],
          title: 'Previsualizar',
          icon: 'file-search'
          // width: 130
        },
        {
          id: 2,
          status: ['generating', 'generated'],
          title: 'Generar',
          icon: 'cog'
          // width: 100
        },
        {
          id: 3,
          status: ['processing', 'processed'],
          title: 'Procesar',
          icon: 'draw'
          // width: 110
        },
        {
          id: 4,
          status: ['declaring', 'declared'],
          title: 'Declarar',
          icon: 'bank'
          // width: 110
        },
        {
          id: 5,
          status: ['distributing', 'distributed'],
          title: 'Distribuir',
          icon: 'send-clock'
          // width: 'auto'
        },
        {
          id: 6,
          status: ['finalized'],
          title: 'Completado',
          icon: 'check'
        }
      ],
      breadcrumbs: {
        main: 'Emisión',
        children: [
          {
            text: 'Emisión por lotes'
          }
        ]
      },
      headers: [
        { text: 'Nombre del archivo', value: 'name', sortable: true },
        { text: 'Fecha de creación', value: 'created', sortable: true },
        { text: 'Fecha de act.', value: 'updated', align: 'end', sortable: true},
        { text: 'Cant.', value: 'count', align: 'end', sortable: true},
        { text: 'Estado', value: 'status' },
        { text: 'Creado por', value: 'user' },
        { text: '', value: 'actions', align: 'end', width: 16 }
    ]
    }),
    computed: {
      ...mapState({
        count: state => state.batch.batchCount,
        documentsList: state => state.batch.batchList
      }),
      widthModalCreateErrors () {
        return this.errors?.length > 6 ? '550' : '578'
      },
      currentChildrenCol () {
        return (number) => {
          let children = this.isArrayOfObjects?.find((children) => number === children?.number)
          return children?.children?.length > 0 ? `${children.col} - ${children.children}` : ''
        }
      },
      currentChildrenDescription () {
        return (number) => {
          return this.isArrayOfObjects?.find((children) => number === children?.number)?.description ?? ''
        }
      },
      heightTable () {
        if (this.$vuetify.breakpoint.height >= 1200) return 'auto'
        return 'calc(100vh - 270px)'
      }
    },
    watch: {
      filter (val) {
        if (val > 0) {
          let query = Object.values(this.stepperList.find((item) => item.id === val).status).toString()
          this.$router.replace({name: this.$route.name, query: {status__in: query} }).catch(() => {})
        } else this.$router.replace({name: this.$route.name, query: null }).catch(() => {})
      },
      $route () {
        this.getListBatch()
      }
    },
    created () {
      if (this.$route.query.status__in) {
        const index = this.stepperList.findIndex((item) => this.$route.query.status__in.split(',').every((s) => item.status?.includes(s)))
        if (index > 0) this.filter = index
      }
      this.$store.dispatch('batch/LIST', {
        resource: 'batch',
        query: this.$route.query
      })
      .then((response) => {
        // const list = Object.entries(groupBy(response.data.results, 'status')).map(([key, value]) => {
        //   return { status: key, count: value.length }
        // })
        // list.forEach((item) => {
        //   const index = this.stepperList.findIndex((s) => s.status.includes(item.status))
        //   if (index !== -1) {
        //     this.stepperList[index].count = !!response.data.next
        //   }
        // })
      })
    },
  methods: {
    createExportErrors (id = '', name = '') {
      this.$store.dispatch('batch/LIST', {
        resource: `batch/${id}/export_error`,
        query: {
          extension: 'xlsx'
        },
        loader: false,
        responseType: 'arraybuffer'
      })
      .then((response) => {
        const content = Buffer.from(response.data, 'base64')
        saveAs(new Blob([content], {type: 'application/xlsx'}), name)
        this.$dialog.message.info('La exportación se realizó con éxito')
      })
      .catch((response) => {
        if (response.toString().search('400') !== -1) {
          this.$dialog.message.info('No hay errores en el proceso del batch')
        } else this.$dialog.message.error('Ha ocurrido un error en la exportación')
      })
      .finally(() => {
        this.dialogExport = false
      })
    },
    createExport () {
      this.$store.dispatch('batch/LIST', {
        resource: `batch/get_template`,
        loader: false,
        responseType: 'arraybuffer'
      })
      .then((response) => {
        const content = Buffer.from(response.data, 'base64')
        saveAs(new Blob([content], {type: 'application/xlsx'}), 'template.xlsx')
        this.$dialog.message.info('La exportación se realizó con éxito')
      })
      .catch(() => {
         this.$dialog.message.error('Ha ocurrido un error en la exportación')
      })
    },
      goTo (id = null) {
        this.$store.commit('base/SET_QUERY', this.$route.query)
        this.$router.push({ name: 'DocumentsBatchRetrieve', params: { id: id }})
      },
      setUploadFile () {
        this.errorFile = this.file?.name?.search('xlsx') === -1 ?? false
      },
      onScroll (e) {
        this.scroll = e.target?.scrollingElement?.scrollTop ?? 0
      },
      getListBatch () {
        this.$store.dispatch('batch/LIST', {
          resource: 'batch',
          query: this.$route.query
        })
      },
      createDocumentsBulk (error = false) {
        this.newBatch.file = this.file
        this.$v.newBatch.$touch()
        if (this.$v.newBatch.$invalid) {
          return false
        }
        const payload = new FormData()
        payload.append('source', this.newBatch.file, this.newBatch.file.name)
        payload.append('import_format', this.newBatch.import_format)
        payload.append('error_load', error)
        this.$store.dispatch('batch/CREATE', {
          resource: 'batch',
          payload: payload,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
          if (this.$helpers.hasSomePermission(['view_batch'])) this.$router.push({name: 'DocumentsBatchRetrieve', params: {id: response.data.id}})
          else {
            this.dialogCreate = false
            this.$dialog.message.info('El proceso se ha creado con éxito')
          }
        })
        .catch((error) => {
          if (error.response.data.file) {
            this.$dialog.message.error(error.response.data.file.toString().replace(/[ ]/g, ''))
          } else {
            this.errors = error.response.data.data
            this.errorSource = error.response.data.url
            let arrayError = []
            this.isArrayOfObjects = []
            error.response.data.data.forEach((err) => {
              for (let i = 0; i < Object.keys(err)?.length; i++) {
                Object.entries(Object.values(err)[i][0]).forEach(([k, v]) => {
                  if (Array.isArray(v) && v?.every(item => typeof item === 'object' && item !== null)) {
                    v.forEach((item) => {
                      Object.entries(item).forEach(([key, value]) => {
                        this.isArrayOfObjects[i] = {
                          number: i + 2,
                          col: k ?? '',
                          children: key,
                          description: Object.values(value)[0]?.toString() ?? ''
                        }
                        arrayError[i] = {
                          number: i + 2,
                          col: key ? `${k}-${key}` : k ?? '',
                          hasChildren: this.isArrayOfObjects?.length,
                          description: Object.values(value)[0]?.toString() ?? ''
                        }
                      })
                    })
                  } else if (!Array.isArray(v)) {
                    Object.entries(v).forEach(([key, value]) => {
                      this.isArrayOfObjects[i] = {
                        number: i + 2,
                        col: k ?? '',
                        children: key,
                        description: Object.values(value)[0]?.toString() ?? ''
                      }
                    })
                    arrayError[i] = {
                      number: i + 2,
                      col: k ?? '',
                      hasChildren: this.isArrayOfObjects?.length,
                      description: Object.values(v)[0]?.toString() ?? ''
                    }
                  } else {
                    arrayError[i] = {
                      number: i + 2,
                      col: k ?? '',
                      description: Object.values(v)[0]?.toString() ?? ''
                    }
                  }
                })
              }
            })
            this.errors = arrayError?.filter((item) => item.col?.length)

            if (!this.errors?.length && !this.isArrayOfObjects?.length) {
              this.errors = [...this.isArrayOfObjects.filter((item) => item !== undefined)]
              this.isArrayOfObjects = []
            }
          }
        })
      },
      clearInput () {
        this.file = null
        this.newBatch.import_format = 'xlsx'
        this.newBatch.file = null
        this.$v.newBatch.$reset()
      },
      handleDialog () {
        this.createDocumentsBulk(true)
        this.dialogCreate = false
        this.errors = []
        this.clearInput()
      }
    },
    validations: {
      newBatch: {
        file: {
          required
        },
        import_format: {
          required
        }
      }
    }
  }
</script>